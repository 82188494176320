import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './Button.styles';

const useStyles = createUseStyles(Styles);

const Button = ({ to, label, type, style, transparent, ...props }) => {
  const classes = useStyles(props);

  return (
    <Link
      style={style}
      to={to}
      className={`${
        type === 'outline' ? classes.buttonOutline : classes.button
      } ${transparent ? classes.buttonTransparent : ''}`}
    >
      {label}
    </Link>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
};

export default Button;
