import Breakpoints from '@utils/breakpoints';
import Colors from '@utils/colors';
import Dimensions from '@utils/dimensions';

const Styles = {
  container: {
    padding: '0 0 40px 0',
  },
  content: {
    maxWidth: Dimensions.contentMidWidth,
    margin: '0 auto',
  },
  singleLesson: {
    width: '100%',
    marginBottom: '50px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  singleLessonImage: {
    width: '420px',
    position: 'relative',
    [`@media (max-width: ${Breakpoints.l})`]: {
      width: '300px',
    },
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      marginLeft: '-20px',
      marginRight: '-20px',
      width: 'calc(100% + 40px)',
    },
  },
  icon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  singleLessonContent: {
    width: 'calc(100% - 420px)',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '40px',
    [`@media (max-width: ${Breakpoints.l})`]: {
      width: 'calc(100% - 300px)',
    },
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      padding: '40px 0 0 0',
    },
  },
  singleLessonText: {
    width: '70%',
    paddingRight: '80px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      paddingRight: '0',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingRight: '0',
      fontSize: '14px',
    },
  },
  name: {
    fontWeight: 400,
    margin: 0,
    fontSize: '24px',
  },
  instructor: {
    margin: '3px 0 0 0',
    fontSize: '16px',
    fontWeigth: 700,
    color: Colors.accent,
  },
  instructorLink: {
    textDecoration: 'none !important',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
  info: {
    marginTop: '20px',
    fontWeight: 700,
    margin: '0 -5px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  singleInfo: {
    margin: '0 5px 5px',
    padding: '2px 5px',
    borderRadius: '5px',
    background: Colors.alternateBg,
  },
  description: {
    marginTop: '20px',
  },
  singleLessonBook: {
    width: '30%',
    textAlign: 'right',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: '20px',
    },
  },
  price: {
    fontSize: '24px',
    marginBottom: '40px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginBottom: '0',
      marginRight: '40px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '16px',
    },
  },
  header: {
    marginBottom: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginBottom: '40px',
    },
  },
  selectContainer: {
    width: '250px',
    '&:first-child': {
      marginRight: '20px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      '&:first-child': {
        marginRight: '0',
        marginBottom: '10px',
      },
    },
  },
  selectLabel: {
    marginRight: '30px',
    fontSize: '14px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      display: 'none',
    },
  },
  loader: {
    margin: '100px auto',
    width: '22px',
  },
  center: {
    textAlign: 'center',
  },
  titleCenter: {
    fontSize: '32px',
    textAlign: 'center',
  },
  fieldLeftTitle: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  containerBg: {
    background: Colors.alternateBg,
    padding: '60px 20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '30px 20px 60px',
    },
  },
};

export default Styles;
