import React from 'react';
import { createUseStyles } from 'react-jss';
// import Select from 'react-select';
import { Link } from 'gatsby';

// import instructors from '@data/instructors.json';
// import descriptions from '@data/descriptions.json';

// import Api from '@api';
// import Colors from '@utils/colors';
// import Utils from '@utils/';

import ButtonOutline from '@components/ButtonOutline/ButtonOutline';
import Button from '@components/Button/Button';
// import BookModal from '@components/BookModal/BookModal';
// import Spinner from '@components/Spinner/Spinner';

import Styles from './Lessons.styles';

const useStyles = createUseStyles(Styles);

const Lessons = props => {
  const classes = useStyles(props);
  // const [loading, handleLoading] = useState(true);
  // const [lessons, handleLessons] = useState([]);
  // const [types, handleTypes] = useState([]);
  // const [modalOpen, handleModalOpen] = useState(false);
  // const [selectedLesson, handleSelectedLesson] = useState(null);

  // const [filter, handleFilter] = useState(null);
  // const [filterDay /*, handleFilterDay*/] = useState(null);

  const handleBook = () => {
    const emailTo = 't.helboe@gmail.com';
    window.open('mailto:' + emailTo, '_self');
  };

  // const handleClick = (l, v) => {
  //   handleSelectedLesson(l);
  //   handleModalOpen(!modalOpen);

  //   if (l) {
  //     window.history.pushState({ prenota: true }, null, '');
  //   } else {
  //     window.history.back();
  //   }
  // };

  // const handleCloseOnBack = () => {
  //   handleSelectedLesson(null);
  //   handleModalOpen(false);
  // };

  // const renderLessons = () => {
  //   let filteredLessons =
  //     filter && filter !== 'all'
  //       ? lessons.filter(el => el.slug === filter)
  //       : lessons;

  //   filteredLessons =
  //     filterDay && filterDay !== 'all'
  //       ? filteredLessons.filter(el => {
  //           const res = el.availabilities.toString().indexOf(filterDay) !== -1;

  //           return res;
  //         })
  //       : filteredLessons;

  //   if (filteredLessons.length === 0 && (filterDay || filter)) {
  //     return (
  //       <h2>
  //         Siamo spiacenti!
  //         <br />
  //         <small>Non ci sono lezioni corrispondenti alla tua ricerca.</small>
  //       </h2>
  //     );
  //   }

  //   return filteredLessons.sort(Utils.compare).map(el => {
  //     const imageList = require(`@static/img/${el.instructor.imgList}`).default;

  //     const price = el.instructor.calendly.prices[el.slug];

  //     return (
  //       <div key={el.id} className={classes.singleLesson}>
  //         <div className={classes.singleLessonImage}>
  //           <img
  //             className={classes.image}
  //             src={imageList}
  //             alt={el.instructor.name}
  //           />
  //         </div>
  //         <div className={classes.singleLessonContent}>
  //           <div className={classes.singleLessonText}>
  //             <h3 className={classes.name}>{el.name}</h3>
  //             <h4 className={classes.instructor}>
  //               <Link className={classes.instructorLink} to={el.instructor.url}>
  //                 {el.instructor.name}
  //               </Link>
  //             </h4>
  //             <div className={classes.info}>
  //               {el.availabilities.map((a, i) => {
  //                 return (
  //                   <div
  //                     key={i}
  //                     className={classes.singleInfo}
  //                     dangerouslySetInnerHTML={Utils.createMarkup(
  //                       a
  //                         .replace('<br/>', ' | ')
  //                         .replace(
  //                           filterDay,
  //                           '<span class="highlight">' + filterDay + '</span>'
  //                         )
  //                     )}
  //                   ></div>
  //                 );
  //               })}
  //             </div>
  //             <p
  //               className={classes.description}
  //               dangerouslySetInnerHTML={Utils.createMarkup(
  //                 el.description_html
  //               )}
  //             ></p>
  //           </div>
  //           <div className={classes.singleLessonBook}>
  //             <div className={classes.price}>{price}</div>
  //             <div className={classes.book}>
  //               <a href={'mailto:' + emailTo + '&subject=' + emailSub + '&body=' + emailBody,}></a>
  //               <ButtonOutline
  //                 to="#"
  //                 label="Prenota"
  //                 onClick={e => {
  //                   e.preventDefault();
  //                   handleClick(el);
  //                 }}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   });
  // };

  // const handleJSONld = lessons => {
  //   lessons.map(l => {
  //     const script = document.createElement('script');
  //     script.type = 'application/ld+json';
  //     script.textContent = JSON.stringify({
  //       '@context': 'https://schema.org/',
  //       '@type': 'Course',
  //       name: 'Liveflow - Pilates live online',
  //       description: l.description_plain,
  //       hasCourseInstance: {
  //         '@type': 'CourseInstance',
  //         courseMode: [l.name, 'online'],
  //         name: l.name,
  //         startDate: new Date(),
  //         location: {
  //           '@type': 'VirtualLocation',
  //           url: 'liveflow.it',
  //         },
  //         instructor: {
  //           '@type': 'Person',
  //           jobTitle: 'Insegnante di Pilates',
  //           name: l.instructor.name,
  //           url: { '@id': `https://liveflow.it${l.instructor.url}` },
  //           worksFor: 'Liveflow',
  //         },
  //         offers: {
  //           '@type': 'Offer',
  //           offeredBy: 'Liveflow',
  //           price: '9.90',
  //         },
  //         description: descriptions[l.type],
  //         organizer: 'Liveflow',
  //       },
  //       provider: {
  //         '@type': 'SportsOrganization',
  //         name: 'Liveflow',
  //       },
  //     });

  //     document.head.appendChild(script);

  //     return true;
  //   });
  // };

  // useEffect(() => {
  //   const accessTokenInstructorsList = instructors.data
  //     .filter(el => el.calendly.accessToken !== '')
  //     .map(el => {
  //       return {
  //         id: el.id,
  //         accessToken: el.calendly.accessToken,
  //         userLink: el.calendly.userLink,
  //         calendlyId: el.calendly.id,
  //       };
  //     });

  //   const currentUser = accessTokenInstructorsList.filter(
  //     el => el.id === '0000007'
  //   )[0];

  //   const get = () => {
  //     Api.events
  //       .get(currentUser.accessToken, currentUser.userLink)
  //       .then(res => {
  //         if (res) {
  //           const d = res.collection
  //             .filter(
  //               e =>
  //                 e.slug !== 'lezione-di-prova' &&
  //                 e.slug !== 'eventi-speciali' &&
  //                 e.slug !== 'consulenza-gratuita'
  //             )
  //             .map(inst => {
  //               inst.instructor = instructors.data.filter(ins => {
  //                 return ins.calendly.userLink === inst.profile.owner;
  //               })[0];

  //               inst.availabilities = inst.instructor.availabilities[inst.slug];

  //               return inst;
  //             });

  //           handleLessons(d);
  //           handleTypes([
  //             ...new Set(d.map(el => ({ name: el.name, slug: el.slug }))),
  //           ]);
  //           handleLoading(false);

  //           handleJSONld(d);
  //         }
  //       });
  //   };

  //   get();

  //   if (window.location.hash) {
  //     handleFilter(window.location.hash.replace('#', ''));
  //   }

  //   // close POPUP on browser back action
  //   window.addEventListener('popstate', function lessonsClose(event) {
  //     handleCloseOnBack();
  //   });

  //   return () => {
  //     window.removeEventListener('popstate', function lessonsClose() {});
  //   };
  // }, []);

  // const optionsDays = [
  //   { value: 'all', label: 'Tutti i giorni' },
  //   { value: 'lun', label: 'Lunedì' },
  //   { value: 'mar', label: 'Martedì' },
  //   { value: 'mer', label: 'Mercoledì' },
  //   { value: 'gio', label: 'Giovedì' },
  //   { value: 'ven', label: 'Venerdì' },
  //   { value: 'sab', label: 'Sabato' },
  //   { value: 'dom', label: 'Domenica' },
  // ];

  const imageList = require(`@static/img/tina-list.jpg`).default;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {/* <div className={classes.header}>
          <div className={classes.selectContainer}>
            <Select
              isSearchable={false}
              placeholder="Filtra per giorno"
              options={optionsDays}
              onChange={val => {
                handleFilterDay(val.value);
              }}
              value={
                filterDay
                  ? optionsDays.filter(el => el.value === filterDay)[0]
                  : null
              }
              theme={theme => ({
                ...theme,
                borderRadius: '4px',
                colors: {
                  ...theme.colors,
                  borderColor: Colors.base,
                  text: Colors.base,
                  primary25: Colors.third,
                  primary: Colors.accent,
                },
              })}
            />
          </div>
          <div className={classes.selectContainer}>
            <Select
              isSearchable={false}
              placeholder="Filtra per categoria"
              options={[{ value: 'all', label: 'Tutte le categorie' }].concat(
                types
                  .map(el => ({ label: el.name, value: el.slug }))
                  .sort((a, b) =>
                    a.value < b.value ? -1 : a.value > b.value ? 1 : 0
                  )
              )}
              onChange={val => {
                handleFilter(val.value);
              }}
              value={
                filter
                  ? [{ value: 'all', label: 'Tutte le categorie' }]
                      .concat(
                        types
                          .map(el => ({ label: el.name, value: el.slug }))
                          .sort((a, b) =>
                            a.value < b.value ? -1 : a.value > b.value ? 1 : 0
                          )
                      )
                      .filter(el => el.value === filter)[0]
                  : null
              }
              theme={theme => ({
                ...theme,
                borderRadius: '4px',
                colors: {
                  ...theme.colors,
                  borderColor: Colors.base,
                  text: Colors.base,
                  primary25: Colors.third,
                  primary: Colors.accent,
                },
              })}
            />
          </div>
        </div> */}
        {/* {loading && (
          <div className={classes.loader}>
            <Spinner loading={loading} />
          </div>
        )} */}

        {/* {renderLessons()} */}

        <div className={classes.singleLesson}>
          <div className={classes.singleLessonImage}>
            <img className={classes.image} src={imageList} alt="Tina Olsen" />
          </div>
          <div className={classes.singleLessonContent}>
            <div className={classes.singleLessonText}>
              <h3 className={classes.name}>Movimento Intuitivo</h3>
              <h4 className={classes.instructor}>
                <Link className={classes.instructorLink} to="/chi-sono">
                  Tina Olsen
                </Link>
              </h4>
              <div className={classes.info}>
                <div className={classes.singleInfo}>
                  <span>mar</span> | <span>18.30 - 19.25</span>
                </div>
                <div className={classes.singleInfo}>
                  <span>gio</span> | <span>9.00 - 9.55</span>
                </div>
                <div className={classes.singleInfo}>
                  <span>sab</span> | <span>10.00 - 10.55</span>
                </div>
                <div className={classes.singleInfo}>
                  <span>dom</span> | <span>18.00 - 18.55</span>
                </div>
              </div>
              <p className={classes.description}>
                <p>
                  Prepara il tuo tappetino per 55 minuti di meditazione in
                  movimento. Riprendiamo insieme la connessione tra mente e
                  corpo eseguendo movimenti consapevoli, lenti e fluidi con il
                  ritmo del nostro respiro.
                </p>
                <p>
                  Faremo una serie di esercizi di Pilates e Garuda, che
                  rispondono alle esigenze di allievi con preparazione e corpi
                  diversi e aumentano il benessere psicofisico.
                </p>
                <p>
                  Il focus è sull'allungamento dei muscoli e la mobilità delle
                  articolazioni. In più, gli esercizi aiutano a rinforzare i
                  muscoli intorno al tronco (CORE) con l'obiettivo di migliorare
                  l’allineamento e la postura migliorando così la qualità della
                  vita di tutti i giorni.&nbsp;
                </p>
                <p>
                  Inoltre, il Movimento Intuitivo ti porta in sintonia con il
                  proprio corpo aiutandoti a:
                </p>
                <ul>
                  <li>facilitare la connessione tra corpo e mente,&nbsp;</li>
                  <li>migliorare l’immagine corporea,&nbsp;</li>
                  <li>aumentare la compassione verso te stessa,&nbsp;</li>
                  <li>apprezzare di più il proprio corpo.’</li>
                </ul>
              </p>
            </div>
            <div className={classes.singleLessonBook}>
              {/* <div className={classes.price}>{price}</div> */}
              <div className={classes.book}>
                <ButtonOutline
                  to="#"
                  label="Prenota"
                  onClick={e => {
                    e.preventDefault();
                    handleBook();
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.containerBg}>
          <div className={classes.content}>
            <div className={classes.row}>
              <div className={classes.center}>
                <strong>
                  Sei pronta a dire BASTA e a riprendere in mano la tua vita?
                </strong>
                <br />
                Prenota qui una consulenza gratuita.
                <br />
                <br />
                <Button to="/consulenza-gratuita" label="Consulenza gratuita" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <BookModal
        open={modalOpen}
        selectedLesson={selectedLesson}
        onCloseModal={() => {
          handleClick(null);
        }}
      /> */}
    </div>
  );
};

export default Lessons;
