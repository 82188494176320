import Colors from '@utils/colors.js';
import Utils from '@utils';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  button: {
    color: Colors.white + ' !important',
    textDecoration: 'none !important',
    backgroundColor: Colors.accent,
    borderRadius: '4px',
    padding: '20px 25px',
    fontSize: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    transition: Utils.transition('background', '.2s'),
    letterSpacing: 1,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.accentDark,
    },
    [`@media (max-width: ${Breakpoints.xxs})`]: {
      padding: '15px 20px',
    },
  },
  buttonOutline: {
    color: Colors.base + ' !important',
    textDecoration: 'none !important',
    backgroundColor: Colors.white,
    border: '2px solid ' + Colors.base,
    borderRadius: '4px',
    padding: '20px 25px',
    fontSize: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    transition: Utils.transition('all', '.2s'),
    letterSpacing: 1,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.base,
      color: Colors.white + ' !important',
    },
    [`@media (max-width: ${Breakpoints.xxs})`]: {
      padding: '15px 20px',
    },
  },
  buttonTransparent: {
    background: 'transparent',
  },
};

export default Styles;
