import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Utils from '@utils/';

import Styles from './PageHeader.styles';

const useStyles = createUseStyles(Styles);

const PageHeader = ({ title, subtitle, where, center, nomargin, ...props }) => {
  const classes = useStyles(props);

  return (
    <div
      className={`${classes.container} ${
        center ? classes.containerCenter : ''
      } ${nomargin ? classes.containerNoMargin : ''}`}
    >
      <h1 className={classes.title}>{title}</h1>
      {where && <p className={classes.where}>{where}</p>}
      {subtitle && (
        <p
          className={`${classes.subtitle} ${
            center ? classes.subtitleCenter : ''
          }`}
          dangerouslySetInnerHTML={Utils.createMarkup(subtitle)}
        ></p>
      )}
    </div>
  );
};

PageHeader.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  where: PropTypes.string,
  center: PropTypes.bool,
  nomargin: PropTypes.bool,
};

export default PageHeader;
