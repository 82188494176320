import Breakpoints from '@utils/breakpoints';
import Dimensions from '@utils/dimensions';
import Colors from '@utils/colors';

const Styles = {
  container: {
    marginBottom: '80px',
    maxWidth: Dimensions.contentMidWidth,
    margin: '0 auto',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      marginBottom: '60px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      marginBottom: '40px',
    },
  },
  containerCenter: {
    textAlign: 'center',
  },
  containerNoMargin: {
    marginBottom: 0,
  },
  title: {
    margin: '0 0 15px 0',
    fontSize: '40px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '28px',
    },
  },
  where: {
    margin: '-20px 0 0 0',
    fontWeight: 700,
    color: Colors.baseLight,
    fontSize: '18px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '16px',
    },
  },
  subtitle: {
    fontSize: '20px',
    margin: 0,
    maxWidth: '600px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '14px',
    },
  },
  subtitleCenter: {
    margin: '0 auto',
  },
};

export default Styles;
