import React from 'react';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import PageHeader from '@components/PageHeader/PageHeader';
import Newsletter from '@components/Newsletter/Newsletter';
import Lessons from '@components/Lessons/Lessons';

const Lezioni = ({ location }) => {
  const title = 'Prenota Movimento Intuitivo';
  const subtitle =
    'Durante una lezione di Movimento Intuitivo puoi riscoprire il piacere di muoverti e imparare ad ascoltare nuovamente il tuo corpo. ';
  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <div className="page_container">
        <PageHeader title={title} subtitle={subtitle} />
        <Lessons />
        <Newsletter />
      </div>
    </Layout>
  );
};

export default Lezioni;
